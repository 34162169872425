/* Default styles for the element */
.responsive-width {
    width: 100%;
}

/* Media query for screens wider than 900px */
@media (min-width: 900px) {
    .responsive-width {
        width: 30%;
    }
} 