@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";

:root {
  --danger: #c4183c;
  --pink: #ff4169;
  --orange: #e63205;
  --orange2: #ff7c5c;
  --warning: #ffb400;
  --teal: #1adba2;
  --success: #17c671;
  --green: #00ac52;
  --blue: #3f5584;
  --info: #00b8d8;
  --primary: #007bff;
  --indigo: #674eec;
  --purple: #8445f7;
  --white: #fff;
  --light: #fbfbfb;
  --light-gray: #f0f0f0;
  --divider: #dbdbdb;
  --gray: #868e96;
  --secondary: #5a6169;
  --gray-dark: #343a40;
  --dark: #212529;

  /* Sidebar */

  /* Cor de fundo do sidebar */
  --sidebar-bg-color: var(--light-gray);
  /* Cor de fundo do item do sidebar */
  --sidebar-item-bg-color: var(--light-gray);
  /* Cor do texto do item do sidebar */
  --sidebar-item-txt-color: var(--dark);
  /* Cor de ícone do item do sidebar */
  --sidebar-item-icon-color: var(--secondary);

  /* Cor de fundo do item do sidebar ao passar o mouse */
  --hover-sidebar-item-bg-color: var(--orange2);
  /* Cor do texto do item do sidebar ao passar o mouse */
  --hover-sidebar-item-txt-color: var(--dark);
  /* Cor de ícone do item do sidebar ao passar o mouse */
  --hover-sidebar-item-icon-color: var(--secondary);

  /* Cor de fundo do item do sidebar quando ativo */
  --active-sidebar-item-bg-color: var(--light);
  /* Cor do texto do item do sidebar quando ativo */
  --active-sidebar-item-txt-color: var(--dark);
  /* Cor do ícone do item do sidebar quando ativo */
  --active-sidebar-item-icon-color: var(--danger);

  /* Navbar */

  /* Cor de fundo do navbar */
  --navbar-bg-color: var(--white);
  /* Cor do texto no navbar */
  --navbar-txt-color: var(--dark);
  /* Cor dos ícones no navbar */
  --navbar-icon-color: var(--secondary);

  /* Cor do nome da empresa no navbar */
  --navbar-brand-txt-color: var(--dark);
  /* Cor do nome da empresa no navbar ao passar o mouse */
  --hover-navbar-brand-txt-color: var(--secondary);

  /* Cor de fundo dos items do dropdown ao passar o mouse */
  --navbar-hover-dropdown-item-bg-color: var(--light-gray);
  /* Cor do texto dos items do dropdown ao passar o mouse */
  --navbar-hover-dropdown-item-txt-color: var(--dark);
  /* Cor dos ícones dos items do dropdown ao passar o mouse */
  --navbar-hover-dropdown-item-icon-color: var(--white);

  /* Cor de fundo dos ícones das notificações */
  --navbar-notification-icon-bg-color: var(--light-gray);
  /* Cor de fundo dos ícones das notificações ao passar o mouse */
  --navbar-hover-notification-icon-bg-color: var(--white);
  /* Cor dos ícones das notificações ao passar o mouse */
  --navbar-hover-notification-icon-color: var(--primary);

  /* Main content */

  /* Cor de fundo do conteúdo principal */
  --main-content-bg-color: var(--light);

  /* Footer */

  /* Cor de fundo do footer */
  --footer-bg-color: var(--white);
  /* Cor de texto do copyright no footer */
  --footer-copyright-txt-color: var(--secondary);
  /* Cor de texto dos links no footer */
  --footer-link-txt-color: var(--primary);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

/* Inputs */
.form-control2 {
  border: 3px solid !important;
  ;
  border-color: #FF6A10 !important;

}

.form-control {
  border-color: #FF6A10 !important;

}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: pink;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: pink;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: pink;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: pink;
}

.no-form-control {
  border: 0;
  border-color: #c7c9ca !important;
}

.form-control-1 {
  border-radius: 5px;
  border: 0;
  text-align: center;
}

.form-control-2 {
  border-color: #FF6A10 !important;
  text-align: center;
}

.form-control-shipment {
  border-color: #495057 !important;
}

.input[type="number"] {
  -moz-appearance: textfield;
}

.form-control:focus {
  border-color: #3f5584 !important;
  box-shadow: 0 0.313rem 0.719rem #ed4e2b1a, 0 0.156rem 0.125rem #0000000f !important;
}

/* Label */
.form-group label {
  color: #808080 !important;
}

/* Button and primary items */
.btn-primary {
  color: #fff;
  border-color: #ed4e2b !important;
  background-color: #ed4e2b !important;
  box-shadow: none;
}

.form-control-check {
  border-color: #FF6A10 !important;
}

.input-group-text {
  border-color: #ed4e2b !important;
}

.input-group-text-shipment {
  border-color: #495057 !important;
}

.btn-secondary {
  color: #fff;
  border-color: #00ac52 !important;
  background-color: #00ac52 !important;
  box-shadow: none;
}

/* .modal {
    text-align: center;
    padding: 0!important;
  }
  
  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  
 */

.modal-dialog2 {
  margin-left: 25% !important;
  min-width: 900px !important;
}

.modal-backdrop {
  z-index: 1040;
  background-color: #e9e5e4 !important;
  opacity: 80% !important;
}

.btn-outline-primary.active {
  color: #fff !important;
  background-color: #ed4e2b !important;
  border-color: #ed4e2b !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #ed4e2b !important;
  border-color: #ed4e2b !important;
}

.fc-button-primary {
  color: #ed4e2b !important;
  border-color: #ed4e2b !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.fc-button-primary:hover {
  color: rgb(34, 24, 24) !important;
  border-color: #ed4e2b !important;
  background-color: #ed4e2b !important;
}

.fc-event,
.fc-event-dot {
  background-color: #ed4e2b !important;
}

.fc-event {
  position: relative;
  display: block;
  font-size: 0.85em;
  line-height: 1.4;
  border-radius: 3px;
  border: 1px solid #ed4e2b;
}

.fc-content {
  display: flex;
  justify-content: center;
}

.fc-day-header {
  background-color: #00ac52;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: white;
}

.fc-body {
  background-color: white;
}

.btn-outline-primary {
  color: #ed4e2b !important;
  border-color: #ed4e2b !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

/* Nav */

.nav-primary a {
  color: #ed4e2b !important;
}

.nav-primary a.active,
.nav-primary a.active:hover {
  background-color: #ed4e2b !important;
  color: white !important;
}

.main-navbar .navbar {
  height: 3.00rem;
}

.form-navbar {
  color: #FF6A10 !important;
  border: 1px solid #FF6A10 !important;
  border-radius: 15px 15px 15px 15px !important;
}

.main-navbar .navbar .nav-link-icon i {
  color: #FF6A10 !important;
}

/* SideBar */
.btn-img {
  position: absolute;
  top: 10%;
  left: 75%;
  transform: translate(-50%, -50%);
}

.btn-ocurrence {
  position: absolute;
  top: 15%;
  left: 75%;
  transform: translate(-50%, -50%);
}

.btn-ocurrence2 {
  position: absolute;
  top: 19%;
  left: 63%;
  transform: translate(-50%, -50%);
}

.main-sidebar {
  box-shadow: 0 -1rem 1rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.5rem rgba(90, 97, 105, 0.12),
    0 0.9375rem 2rem rgba(90, 97, 105, 0.1),
    0 0.4375rem 0rem rgba(165, 182, 201, 0.1) !important;
}

/* CheckBox */
.checkbox {
  vertical-align: middle;
  margin-left: 50px;
}

.nav-wrapper {
  background-color: #3a3a3a !important;
}

.main-sidebar .nav .nav-item .nav-link {
  background-color: #3a3a3a !important;
  color: white !important;
}

/* Para telas pequenas, centralize tudo e talvez aumente o tamanho do texto ou ícone para facilidade de clique. */
@media (max-width: 576px) {
  .border-nav-item {
    text-align: center;
  }

  .border-nav-item img {
    width: 50px;
    height: auto;
  }
}

/* Ajustes para telas médias, se necessário */
@media (min-width: 577px) and (max-width: 992px) {
  /* Seus estilos aqui */
}

/* Para telas grandes, talvez queremos manter o padrão ou fazer pequenos ajustes. */
@media (min-width: 993px) {
  /* Seus estilos aqui */
}


.main-sidebar .nav .nav-item .nav-link i {
  color: white !important;
}

.main-sidebar .nav .nav-item .nav-link:hover {
  color: var(--hover-sidebar-item-txt-color) !important;
  background-color: var(--hover-sidebar-item-bg-color) !important;
  box-shadow: none !important;

  /*inset 0.1875rem 0 0 var(--hover-sidebar-item-txt-color)!important;*/
}

.main-sidebar .nav .nav-item .nav-link:hover i {
  color: var(--hover-sidebar-item-icon-color) !important;
}

.main-sidebar .nav .nav-item .nav-link.active {
  color: var(--active-sidebar-item-txt-color) !important;
  background-color: var(--active-sidebar-item-bg-color) !important;
  box-shadow: none !important;

  /*inset 0.1875rem 0 0 var(--active-sidebar-item-txt-color)*/
}

.main-sidebar .nav .nav-item .nav-link.active i {
  color: var(--active-sidebar-item-icon-color) !important;

}

/* Navbar */

.navbar-brand {
  color: var(--navbar-brand-txt-color) !important;
}

.navbar-brand:hover {
  color: var(--hover-navbar-brand-txt-color) !important;
}

.main-navbar,
.navbar-search,
.main-navbar .dropdown,
.main-navbar .dropdown-menu,
.main-navbar .dropdown-item {
  background-color: var(--navbar-bg-color) !important;
  color: var(--navbar-txt-color) !important;
}

.bootstrap-datetimepicker {
  z-index: 9999 !important;
}

.main-navbar .dropdown-item:hover {
  background-color: var(--navbar-hover-dropdown-item-bg-color) !important;
  color: var(--navbar-hover-dropdown-item-txt-color) !important;
}

.main-sidebar__search {
  background-color: var(--navbar-bg-color) !important;
}

.main-navbar .material-icons {
  color: var(--navbar-icon-color) !important;
}

.main-navbar .dropdown-item:hover i {
  color: var(--navbar-hover-dropdown-item-icon-color) !important;
}

.main-navbar .navbar .notifications .dropdown-menu .notification__icon {
  background-color: var(--navbar-notification-icon-bg-color) !important;
  box-shadow: none !important;
}

.main-navbar .navbar .notifications .dropdown-item:hover .notification__icon {
  background-color: var(--navbar-hover-notification-icon-bg-color) !important;
}

.main-navbar .navbar .notifications .dropdown-item:hover .notification__icon i {
  color: var(--navbar-hover-notification-icon-color) !important;
}

/* Main Content */

.main-content-container {
  /*background-color: #efefef !important;*/
}


/* Footer */

.main-footer {
  background-color: var(--footer-bg-color) !important;
  box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.12) !important;
}

.main-footer .nav-link {
  color: var(--footer-link-txt-color) !important;
}

.main-footer .copyright {
  color: var(--footer-copyright-txt-color) !important;
}

/* Paginação */

.pagination .btn-secondary.focus,
.pagination .btn-secondary:hover {
  box-shadow: none !important;
}

/* custom file */

.custom-file-esc .custom-file-label {
  height: calc(1.5em + 0.75rem + 9px);
  font-weight: 600;
  font-size: 11px;
  line-height: 2.4;
  text-transform: uppercase;
  color: var(--esc-orange);
  background-color: transparent;
  overflow: hidden;
}

.custom-file-esc .custom-file-label::after {
  height: calc(1.5em + 0.75rem + 9px);
  line-height: 2.4;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f574" !important;
  color: var(--esc-orange);
  border: 0;
  background-color: transparent;
}

.custom-file-esc-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 6.5px);
  padding: 0.375rem 0.75rem;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #06222f;
  background-color: transparent;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  overflow: hidden;
}

.custom-file-esc-label::before {
  height: calc(1.5em + 0.75rem + 6.5px);
  line-height: 2.4;
  font-family: "Font Awesome 5 Free";
  font-weight: 1000;
  content: "\f574" !important;
  color: #06222f;
  border: 0;
  margin-right: 4px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Datepicker 100% width */
.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100% !important;
}

/* Datepicker Modal */

/* .react-datepicker-popper {
  position: sticky !important;
  z-index: 9999 !important;
} */

/* Geral */

.bg-danger {
  background-color: var(--danger) !important;
}

.bg-pink {
  background-color: var(--pink) !important;
}

.bg-orange {
  background-color: var(--orange) !important;
}


.bg-warning {
  background-color: var(--warning) !important;
}

.bg-teal {
  background-color: var(--teal) !important;
}

.bg-success {
  background-color: var(--success) !important;
}

.bg-info {
  background-color: var(--info) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-blue {
  background-color: rgb(4, 49, 180) !important;
}

.bg-blue-claro {
  background-color: rgb(0, 215, 253) !important;
}

.bg-verde {
  background-color: rgb(0, 166, 90) !important;
}

.bg-roxo {
  background-color: rgb(111, 79, 143) !important;
}

.bg-indigo {
  background-color: var(--indigo) !important;
}

.bg-purple {
  background-color: var(--purple) !important;
}

.bg-white {
  background-color: var(--white) !important;
}

.bg-light {
  background-color: var(--light) !important;
}

.bg-light-gray {
  background-color: var(--light-gray) !important;
}

.bg-gray {
  background-color: var(--gray) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.bg-gray-dark {
  background-color: var(--gray-dark) !important;
}

.bg-dark {
  background-color: var(--dark) !important;
}

.txt-danger {
  color: var(--danger) !important;
}

.txt-pink {
  color: var(--pink) !important;
}

.txt-orange {
  color: var(--orange) !important;
}

.txt-warning {
  color: var(--warning) !important;
}

.txt-teal {
  color: var(--teal) !important;
}

.txt-success {
  color: var(--success) !important;
}

.txt-info {
  color: var(--info) !important;
}

.txt-primary {
  color: var(--primary) !important;
}

.txt-indigo {
  color: var(--indigo) !important;
}

.txt-purple {
  color: var(--purple) !important;
}

.txt-white {
  color: var(--white) !important;
}

.txt-light {
  color: var(--light) !important;
}

.txt-light-gray {
  color: var(--light-gray) !important;
}

.txt-gray {
  color: var(--gray) !important;
}

.txt-secondary {
  color: var(--secondary) !important;
}

.txt-gray-dark {
  color: var(--gray-dark) !important;
}

.txt-dark {
  color: var(--dark) !important;
}

.lost-text {
  font-size: 13px;
}

.background-component {
  min-height: 100vh;

  min-Height: '100vh';
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.form-login {
  background-color: white !important;
  color: #000 !important;
  font-weight: 500 !important;
  font-size: medium !important;
  text-align: center !important;
  border-width: 2px !important;
}

.form-login:focus {
  border-color: white !important;
  color: #000 !important;
  background-color: white !important;
}

.form-login::placeholder {
  color: #000 !important;
  font-weight: 500 !important;
  font-size: medium !important;
}

.title {
  margin-left: 20px !important;
  margin-top: 13px;
  margin-bottom: 10px;
}

.search {
  margin-left: 20px;
  width: 100% !important;
}

.end-itens {
  display: flex !important;
  justify-content: flex-end;
}

.text-car {
  text-align: left;
}

.card-text {
  height: 125px;
}

.maxHeight {
  height: 100% !important;
}

.center {
  display: flex !important;
  justify-content: center !important;
}

.center-vertical {
  align-items: center !important;
  display: flex !important;
}

@media (max-width: 768px) {
  .center {
    justify-content: center !important;
    margin-top: 1px;
  }
}

select#farm {
  font-weight: 600;
}

.border-ocurrence {
  border: 3px solid green !important;
  z-index: 99;
  background-color: white !important;
}

.border-orange2 {
  border: 2px solid #FF6A10 !important;
  z-index: 99;
  background-color: white !important;
}

.border-orange {
  border: 2px solid #FF6A10 !important;
  z-index: 99;
  background-color: white !important;
}

.border-plan-maintenance {
  border: 3px solid #3f5584 !important;
  z-index: 99;
  background-color: white !important;
}

.col-list-ocurrence {
  background-color: white !important;
  height: 100% !important;
  margin-left: -60px;
}

.col-list-plan {
  background-color: white !important;
  height: 100% !important;
  margin-left: -100px;
}

.align-vertical {
  align-items: center !important;
  display: flex;
}

.content-list-big {
  padding-left: 90px !important;
  width: 100% !important;
  height: 98px !important;
}

.content-list-small {
  padding-left: 64px !important;
  width: 100% !important;
  height: 78px !important;
}

.content-list-small2 {
  padding-left: 64px !important;
  width: 100% !important;
  height: 88px !important;
}

.card-list-title {
  height: 30px !important;
  color: white;
  font-weight: 600;
  align-items: center !important;
  display: flex;
}

.title-green {
  background-color: #00ac52 !important;
}

.title-red {
  background-color: #ff2f00 !important;
}

.title-orange {
  background-color: rgb(252, 114, 64) !important;
}

.title-yellow {
  background-color: #ffb400 !important;
}

.title-black {
  background-color: #000000 !important;
}


.title-blue {
  background-color: #3f5584 !important;
}

.end-items {
  justify-content: end !important;
  display: flex;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: transparent;
  background-color: #36b372 !important;
  box-shadow: none;
  border-radius: 90%;
}

.custom-control-label::before {
  top: 0.1875rem;
  left: 0;
  width: 1.125rem;
  height: 1.125rem;
  background-color: white !important;
  border: 1px solid #e1e5eb;
  border-color: transparent;
  border-radius: 90% !important;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  box-shadow: none;
}

.button-card-green {
  background-color: #00ac52 !important;
  height: 30px !important;
  border: 1px !important;
}

.button-card-transparent {
  background-color: transparent !important;
  height: 20px !important;
  border: 1px !important;
}

.button-card-orange {
  background-color: #ed4e2b !important;
  height: 30px !important;
  border: 1px !important;
}

.button-card-blue2 {
  background-color: #3f5584 !important;
  height: 30px !important;
  border: 1px !important;
}

.button-card-blue {
  background-color: hsla(0, 0%, 0%, 0) !important;
  height: 30px !important;
  border: 1px !important;
}

.button-list {
  background-color: #00ac52 !important;
  border: 0px !important;
}

.button-list2 {
  background-color: #3f5584 !important;
  border: 0px !important;
}

.button-list3 {
  background-color: #f9694e !important;
  border: 0px !important;
}

.button-list4 {
  background-color: #00ac52 !important;
  border: 0px !important;
}

.black {
  color: #4a4a4a;
  font-weight: 600;
}

.green {
  color: #00ac52;
  font-weight: 600;
}

.border-picture {
  border: 1px solid #FF6A10 !important;
  background-color: white !important;
}

.border-orange {
  border: 1px solid #FF6A10 !important;
  border-radius: 10px !important;
}

.border-user-ocurrence {
  border-bottom: 1px solid #5a616d !important;
  border-width: 30% !important;
}

.btn-transparent {
  background-color: transparent !important;
  border-color: transparent !important;
}

.material-icon-sm {
  font-size: 1.3625rem !important;
}

.form-group {
  margin-bottom: 8px;
}

.main-sidebar .nav .nav-item .nav-link {
  border-bottom: rgb(56, 55, 55) solid 1px !important;
  padding: 0.2675rem 0.4625rem !important;
}

.form-control-medium {
  height: auto;
  padding: 0.4375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  border-color: #c02600 !important;
  background-color: #0eb3ff;
  border: 1px solid #fd0303;
  font-weight: 300;
  will-change: border-color, box-shadow;
  border-radius: 0.25rem;
  box-shadow: none;
  transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.form-control-large {
  height: auto;
  padding: 0.4375rem 0.75rem;
  font-size: 2.7125rem !important;
  line-height: 1.5;
  color: #495057;
  border-color: #FF6A10 !important;

  background-color: #fff;
  border: 1px solid #e1e5eb;
  font-weight: 300;
  will-change: border-color, box-shadow;
  border-radius: 0.25rem;
  box-shadow: none;
  transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.form-control-large2 {
  height: auto;
  padding: 0.4375rem 0.35rem;
  font-size: 2.7125rem !important;
  line-height: 1.5;
  color: #495057;
  border-color: #FF6A10 !important;
  width: 220px;
  background-color: #fff;
  border: 1px solid #e1e5eb;
  font-weight: 300;
  will-change: border-color, box-shadow;
  border-radius: 0.25rem;
  box-shadow: none;
  transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.btn-circle {
  height: 82px !important;
  width: 82px !important;
  border-radius: 100% !important;
  border: 4px solid white !important;
  box-shadow: 5px 5px 20px 1px rgba(0, 0, 0, 0.5) !important;
}

.formLogin {
  background-color: #fff !important;
  color: #000;
  border-color: rgba(255, 255, 255, 0.5) !important;
  border-radius: 10px !important;
  padding: 8px 8px 8px 40px !important;
}

.formLogin:focus {
  color: #000;
  background-color: #fff !important;
  border-color: rgba(255, 255, 255, 0.5) !important;
  border-radius: 10px !important;
  padding: 8px 8px 8px 40px !important;
}

.formLogin::placeholder {
  color: #000;
  text-align: left;
}

.text-left {
  text-align: left !important;
}

.btn-login {
  height: 44px !important;
  border-radius: 13px !important;
}

h5.modal-title {
  width: 100% !important;
}

.center-vertical {
  align-items: "center !important";
  display: "flex !important";
}

.card-execution-info {
  width: 90% !important;
}

.card-execution {
  width: 74% !important;
  background-color: #e7e7e7 !important;
}

.content-list-small.border.d-flex.flex-column.justify-content-center {
  background-color: white !important;
}

.content-list-small2.border.d-flex.flex-column.justify-content-center {
  background-color: white !important;
}

.iconlogin {
  opacity: 1;
  position: absolute;
  z-index: 1;
  font-size: 18px;
  margin-left: 12px;
  margin-top: 12px;
  /* color: #ac867d; */
  color: rgba(255, 255, 255, 0.7);
}

input[type="color"].custom-color-picker {
  border-radius: 4px;
  overflow: hidden;
  border-width: 0px;
  width: 16px !important;
  height: 20px !important;
  padding: 0;
}

input[type="color"].custom-color-picker-large {
  border-radius: 4px;
  overflow: hidden;
  border-width: 0px;
  width: 40px !important;
  height: 40px !important;
  padding: 0;
}

input[type="color"].custom-color-picker::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 4px;
  padding: 0;
}

input[type="color"].custom-color-picker::-webkit-color-swatch {
  border-radius: 4px;
  border: none;
  padding: 0;
}

.titleCard {
  position: absolute;
  top: -19.5px;
  text-transform: uppercase;
}

.itemList:hover {
  background-color: #d6d5d4;
}

.card-permitions {
  height: 400px;
}

.card .card-header.header {
  padding: 10px;
  background-color: #6e6e6e;
  color: white;
}

.card-body.barra-rolagem {
  padding: 0px;
}

.card .card-footer {
  background-color: #6e6e6e;
  padding: 10px;
}

.barra-rolagem {
  overflow: rgb(185, 185, 185);
  overflow-x: hidden;
}

thead th.rx {
  background-color: #FF6A10;
}

thead th:hover {
  background-color: #fc613a;
}

thead th label {
  margin-left: 3px;
}

tbody td label {
  margin-left: 3px;
}

@media (max-width: 575.98px) {
  .card.ex {
    margin-bottom: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .card.ex {
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .card.ex {
    margin-bottom: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .card.ex {
    margin-bottom: 15px;
  }
}

@media (min-width: 1200px) {
  .card.ex {
    margin-bottom: 15px;
  }
}

.border-nav-item {
  border-bottom: rgb(87, 82, 82) solid 1px !important;
}

.opacity-label {
  opacity: 0.5 !important;
}

.center-input {
  text-align: center !important;
}

.bold {
  font-weight: bold !important;
}

.btn-circle.btn.center.btn.btn-primary.btn-block:hover {
  margin-bottom: 20px !important;
  box-shadow: 4px 4px 4px #999 !important;
}

.height-alter-entry {
  height: 50px !important;
}

.btn-green {
  background-color: #02ac50 !important;
  color: white !important;
  border-color: #02ac50 !important;
}

.btn-blue {
  background-color: #5768a5 !important;
  color: white !important;
  border-color: #5768a5 !important;
}

.form-control-large-apart {
  min-height: 81.1px;
  padding: 0.4375rem 0.75rem;
  font-size: 1.6rem !important;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #e1e5eb;
  font-weight: 300;
  will-change: border-color, box-shadow;
  border-radius: 0.25rem;
  box-shadow: none;
  transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.fontColor {
  color: #FF6A10;
}

.buttonCloseSimulation {
  background-color: transparent !important;
  margin: 0px !important;
  border: none !important;
  padding: 0 !important;
}

.buttonTrash {
  background-color: #c4183c !important;
}

.formLogin2 {
  border-radius: 13px !important;
  padding: 8px 8px 8px 40px !important;
  font-weight: 500 !important;
  font-size: medium !important;
  border-width: 2px !important;
}

.button-inventory {
  background-color: #425894 !important;
  border: none !important;
}

.btn-gray {
  background-color: #514f5c !important;
  border: none !important;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(206, 206, 206, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.btn-red {
  background-color: #cc4649 !important;
}

.btn-orange {
  background-color: #8d391f !important;
}


.btn-orange2 {
  background-color: var(--orange2) !important;
}

.button-blue {
  background-color: #204aee !important;
  border: 0 !important;
}

.material-icons {
  align-items: center !important;
  margin-right: 5px;
}